import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Backdrop, CircularProgress } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

const theme = createTheme({
    typography: { fontFamily: `"Noto Sans", sans-serif`, fontSize: 16 },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const loading = (
    <div
        style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        }}
    >
        <Backdrop
            sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
        >
            <CircularProgress style={{ color: "orange" }} />
        </Backdrop>
    </div>
);

root.render(
    <React.StrictMode>
        <Suspense fallback={loading}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>
);

reportWebVitals();
