import { FC } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import loadable from "@loadable/component";

const Home = loadable(() => import("./components/Home/Home"));
const Support = loadable(() => import("./components/Support/Support"));

const App: FC = () => {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/support" element={<Support />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </div>
    );
};
export default App;
